export const DEFAULT_APP_PAGE_NO = 1
export const DEFAULT_APP_PAGE_SIZE = 24
export const DEFAULT_APP_SORT_DIRECTION = 'asc'
export const DEFAULT_APP_REVERSE_SORT_DIRECTION = 'desc'

export const DEFAULT_MANAGEMENT_PAGE_NO = 1
export const DEFAULT_MANAGEMENT_PAGE_SIZE = 24
export const DEFAULT_MANAGEMENT_SORT_DIRECTION = 'asc'
export const DEFAULT_MANAGEMENT_REVERSE_SORT_DIRECTION = 'desc'

export const TinyAPIKey = 'obs9s235221j0xwl0xqq1w93p1br7qlnzx902f7wbmdy5r8j'