import { SERVER_URL } from "../utils/server/server";
import { mainFetcher } from "../utils/services/service";

export const updateNotification = async (notification) => {
    return mainFetcher('put', notification, SERVER_URL + '/api/notification/update')
};

export const notifyUsersByEnabled = async (notification) => {
    return mainFetcher('post', notification, SERVER_URL + '/api/notification/notify-users-by-enabled')
};

export const notifyUsersByEmailVerified = async (notification) => {
    return mainFetcher('post', notification, SERVER_URL + '/api/notification/notify-users-by-email-verified')
};

export const notifyUsersByAccountNonLocked = async (notification) => {
    return mainFetcher('post', notification, SERVER_URL + '/api/notification/notify-users-by-account-non-locked')
};

export const notifyListedUsers = async (notification) => {
    return mainFetcher('post', notification, SERVER_URL + '/api/notification/notify-listed-users')
};

export const notifyAllUsers = async (notification) => {
    return mainFetcher('post', notification, SERVER_URL + '/api/notification/notify-all-users')
};

export const getNotificationById = async (notificationId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/notification/' + notificationId)
};

export const deleteNotificationById = async (notificationId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/notification/' + notificationId)
};

export const getNotificationsByUserId = async (userId, isRead, seen, pageNo , pageSize, sortField, sortDir) => {
    
    isRead = isRead !== null ? '&isRead=' + isRead : ''
    seen = seen !== null ? '&seen=' + seen : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + isRead
    + seen

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/user/' + userId + '?' + urlParams )
};

export const getAllNotifications = async ( pageNo , pageSize, sortField, sortDir) => {
    

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/find-all' + '?' + urlParams )
};

export const getNotificationsBySeen = async ( seen, pageNo , pageSize, sortField, sortDir) => {
    
    seen = seen !== null ? '&seen=' + seen : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + seen

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/find-all-by-seen' + '?' + urlParams )
};

export const getNotificationsByIsRead = async ( isRead, pageNo , pageSize, sortField, sortDir) => {
    
    isRead = isRead !== null ? '&isRead=' + isRead : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + isRead

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/find-all-by-is-read' + '?' + urlParams )
};

export const getNotificationsByIsReadAndSeen = async ( isRead, seen, pageNo , pageSize, sortField, sortDir) => {
    
    isRead = isRead !== null ? '&isRead=' + isRead : ''
    seen = seen !== null ? '&seen=' + seen : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + isRead
    + seen

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/find-all-by-is-read-and-seen' + '?' + urlParams )
};

export const countAllNotifications = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/notification/count')
};


export const countNotificationsByUserId = async (userId, isRead, seen) => {
    
    isRead = isRead !== null ? '&isRead=' + isRead : ''
    seen = seen !== null ? '&seen=' + seen : ''

    const urlParams = isRead + seen

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/count-by-user/' + userId + '?' + urlParams )
};

export const countNotificationsBySeen = async ( seen) => {
    
    seen = seen !== null ? '&seen=' + seen : ''

    const urlParams = seen

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/count-by-seen' + '?' + urlParams )
};

export const countNotificationsByIsRead = async ( isRead) => {
    
    isRead = isRead !== null ? '&isRead=' + isRead : ''

    const urlParams = isRead

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/count-by-is-read' + '?' + urlParams )
};

export const countNotificationsByIsReadAndSeen = async ( isRead, seen) => {
    
    isRead = isRead !== null ? '&isRead=' + isRead : ''
    seen = seen !== null ? '&seen=' + seen : ''

    const urlParams = isRead + seen

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/count-by-is-read-and-seen' + '?' + urlParams )
};
